.masterdealer-world{
    display: none;
}

.button-position{
    width: 100%;
    margin-top: 5vh;
}

.button-position button{
  background-color: #ffffff;
  font-family: 'Poppins', sans-serif;
  margin-left: 10%;
  color: #2c88f7;
  font-weight: 500;
  width: 80%;
  border: 0;
  border-radius: 5px;
  padding: 2vw;
  height: 15vw;
  font-size: 6vw;
  filter: drop-shadow(0px 4px 3px rgb(0, 0, 0, 0.25));
  margin-top: 4vh;
  cursor: pointer;
}

.button-image-analytics{
  width: 18%;
  position: fixed;
  height: 100%;
  margin-left: -28.5%;
  margin-top: -4%;
}
.button-image-assigner{
  width: 18%;
  position: fixed;
  height: 100%;
  margin-left: -29%;
  margin-top: -3%;
}
.button-image-crm{
  width: 20%;
  position: fixed;
  height: 100%;
  margin-left: -37%;
  margin-top: -3%;
}
.button-image-geo{
  width: 15%;
  position: fixed;
  height: 100%;
  margin-left: -23.5%;
  margin-top: -4%;
}

@media screen and (min-width: 1001px) {
  .where-sales-happen{
      display: flex;
      width: 100%;
      flex-direction: row-reverse;
      color: #ffffff;
  }

  .masterdealer-world{
      display: block;
      width: 35%;
      transform: scaleX(-1);
      z-index: -1;
  }

  .button-position{
      width: 100%;
      margin-top: 8vh;
    }
      
  .button-position button{
    margin-left: 10%;
    width: 60%;
    padding: 1vw;
    height: 5vw;
    font-size: 2vw;
    margin-top: 5vh;
  }
  
  .button-image-analytics{
    width: 12%;
    margin-left: -34.5%;
    margin-top: -3%;
  }
  .button-image-assigner{
    width: 11%;
    margin-left: -34.5%;
    margin-top: -2.5%;
  }
  .button-image-crm{
    width: 12%;
    position: fixed;
    height: 100%;
    margin-left: -40%;
    margin-top: -3%;
  }
  .button-image-geo{
    width: 10%;
    margin-left: -31.5%;
    margin-top: -3%;
  }
}