.top-bar {
  display: flex;
  height: 17vw;
  width: 100vw;
  font-family: "Inter", sans-serif;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 99;
  overflow: hidden;
}

.img-container-top-bar {
  width: 100vw;
}
.img-container-top-bar img {
  width: 30vw;
  margin-left: 5%;
  margin-top: 3vw;
}

@media screen and (min-width: 1001px) {
  .top-bar {
    height: 5vw;
  }
  .img-container-top-bar img {
    width: 9vw;
    margin-left: 2vw;
    margin-top: 0.75vw;
  }
}
