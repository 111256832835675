input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: 20vw;
  background-color: #2c88f7;
  user-select: none
}

@media screen and (min-width: 1001px) {
  body {
    background-position-y: -25vw;
  }
  noscript{
    display: none;
  }
  script{
    display: none;
  }

}
